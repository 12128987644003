import React from "react";
import { Link } from "gatsby";

import { markdownFormatWithoutPTags } from "../helpers";

class ImageGridCell extends React.Component {
  constructor(props) {
    super();

    this.buildLinks = this.buildLinks.bind(this);
  }

  buildLinks(type, referenceList, label) {
    const image = this.props.image;
    let list = [];

    switch (type) {
      case 'narrative':
        list = image.narratives;
        break;
      case 'artist':
        list = image.artists;
        break;
      case 'venue':
        list = image.venues;
        break;
      default:
        break;
    }

    if (list && list.length > 0) {
      return (
        <div>
          <h3>{label}</h3>
          <ul>
            {list.map((reference, idx) => {
              const item = referenceList.filter(item => {
                return (item.node.reference === reference);
              })[0];
              let link;

              switch (type) {
                case 'narrative':
                  const exhibit = this.props.exhibits.filter(({ node }) => {
                    return (node.reference === item.node.exhibit);
                  })[0];

                  link = (
                    <Link to={`/${item.node.exhibit}/narratives/${item.node.reference}`}>
                      “{item.node.title}” from {exhibit.node.long_title}
                    </Link>
                  );
                  break;
                case 'artist':
                  link = (
                    <Link to={`/artists/${item.node.reference}`}>
                      {item.node.canonical_name}
                    </Link>
                  );
                  break;
                case 'venue':
                  link = (
                    <Link to={`/venues/${item.node.reference}`}>
                      {item.node.canonical_name}
                    </Link>
                  );
                  break;
                default:
                  link = null;
                  break;
              }

              return (
                <li key={idx}>
                  {link}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return null;
  }

  render() {
    const image = this.props.image;

    return (
      <li className="images-grid__cell">
        <a href={`/images/content/${image.fullPath}`}
           data-fancybox=''>
          <img src={`/images/content/${image.thumbPath}`} alt={image.alt} />
        </a>
        <div className="images-grid__details">
          {(image.caption.length > 0) ? (
              <span className="caption"
                    dangerouslySetInnerHTML={{
                      __html: markdownFormatWithoutPTags(image.caption) }} />
          ) : null}
          {(image.credit.length > 0) ? (
              <span className="credit"
                    dangerouslySetInnerHTML={{
                      __html: markdownFormatWithoutPTags(image.credit) }} />
          ) : null}
          <div className="links">
            {this.buildLinks('narrative', this.props.narratives, 'Appears in…')}
            {this.buildLinks('artist', this.props.artists, 'Artists')}
            {this.buildLinks('venue', this.props.venues, 'Venues')}
          </div>
        </div>
      </li>
    );
  }
}

export default ImageGridCell;